import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
// components
import Notifications from '../Notifications';
// calendar libraries
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// services
import DealerService from '../../services/DealerService';
import ProductTypeService from '../../services/ProductTypeService';
// other
import moment from 'moment';
import Select from 'react-select';
import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Input
} from 'reactstrap';
// icons
import greyX from '../../images/grey-x.svg';


function ContractFiltersModal({ 
    contractsFilters, filtersModalOpen, handleFiltersModalClose, handleSubmitFilters, applyResetTable 
}) {

    const initializeDealerLabel = () => {
        if (contractsFilters.dealer !== null) {
            return {
                value: contractsFilters.dealer.name,
                label: contractsFilters.dealer.name
            }
        } else {
            return null
        }
    };

    const [initialRender, setInitialRender] = useState(true)
    const [vinSearchFilter, setVinSearchFilter] = useState(contractsFilters.vin)
    const [allDealers, setAllDealers] = useState([]);
    const [allDealersLabel, setAllDealersLabel] = useState([])
    const [dealer, setDealer] = useState(contractsFilters.dealer);
    const [dealerLabel, setDealerLabel] = useState(() => initializeDealerLabel())
    // date
    const [startDate, setStartDate] = useState(moment(contractsFilters.startDate))
    const [endDate, setEndDate] = useState(moment(contractsFilters.endDate))

    const [idSearchFilter, setIdSearchFilter] = useState(contractsFilters.contractId)
    const [customerSearchFilter, setCustomerSearchFilter] = useState(contractsFilters.contractCustomer)
    const [productNameFilter, setProductNameFilter] = useState(contractsFilters.contractProductName)
    // const [productTypeFilter, setProductTypeFilter] = useState(contractsFilters.contractProductType)
    // const [productTypeLabels, setProductTypeLabels] = useState([])

    // get dropdown info
    useEffect(() => { 
        setInitialRender(false)

        // get dealers
        DealerService.getAllDealers().then(response => {
            setAllDealers(response.data)
            setAllDealersLabel(response.data.map((dealer) => {
                return {
                    value: dealer.name,
                    label: dealer.name
                }
            }).sort((a, b) => a['label'].localeCompare(b['label'])))
        }).catch(err => {
            Notifications("error", err['message'])
        })

        // // get product types
        // ProductTypeService.getProductTypes()
        //     .then(response => {
        //         let productTypeList = response.data.map((pt) => {
        //             return {
        //                 value: pt.id,
        //                 label: pt.name
        //             }
        //         })
        //         productTypeList.sort((a, b) => a['label'].localeCompare(b['label']))
        //         setProductTypeLabels(productTypeList)
        //     }, e => {
        //         console.log(e)
        //         Notifications('error', 'Unable to fetch product types list.')
        //     })
    }, [])


    useEffect(() => {
        if (!initialRender) {
            setVinSearchFilter('')
            setDealer(null)
            setDealerLabel(null)
            setStartDate(moment().subtract(30, 'days'))
            setEndDate(moment())
            setIdSearchFilter('')
            setCustomerSearchFilter('')
            // setProductNameFilter('')
            // setProductTypeFilter(null)
        }
    }, [applyResetTable])

    const handleDealerChange = (dealerLabel) => {
        setDealerLabel(dealerLabel)
        const selectedDealer = allDealers.find((dealer) => dealer.name === dealerLabel.value)
        setDealer(selectedDealer)
    }

    // const handleProductTypeChange = (productTypeLabel) => {
    //     setProductTypeFilter(productTypeLabel)
    // }


    const onSubmit = () => {
        const startDateDay = startDate.utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).toISOString()
        const endDateDay = endDate.utcOffset(0).set({hour:23,minute:59,second:59,millisecond:599}).toISOString()
        const filters = {
            vinSearchFilter: vinSearchFilter,
            dealerSearchFilter: dealer,
            startDateDay: startDateDay,
            endDateDay: endDateDay,
            idSearchFilter: idSearchFilter,
            customerSearchFilter: customerSearchFilter
            // productNameFilter: productNameFilter,
            // productTypeFilter: productTypeFilter

        }
        handleSubmitFilters(filters) 

    }; 

    return (
        <Modal
            isOpen={filtersModalOpen}
            onRequestClose={handleFiltersModalClose}
            closeTimeoutMS={200}
            ariaHideApp={false}
            className='modal-contract__filters'
        >
            <div className="modal-header">
                <h2>Filters</h2>
                <button className="close" onClick={handleFiltersModalClose}>
                    <img src={greyX} alt="Close"/>
                </button>
            </div>
            <Row>
                <Col xs={12} md={12}>
                    <div>
                        <span>Contract ID:</span>
                        <input 
                            type='text'
                            placeholder='Enter Contract ID'
                            value={idSearchFilter}
                            onChange={(e) => setIdSearchFilter(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <div>
                        <span>VIN:</span>
                        <input 
                            type='text'
                            placeholder='Enter VIN'
                            value={vinSearchFilter}
                            onChange={(e) => setVinSearchFilter(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                {/*<Col xs={12} md={6}>
                    <div>
                        <span>Customer:</span>
                        <input 
                            type='text'
                            placeholder='Enter Contract Owner Name'
                            value={customerSearchFilter}
                            onChange={(e) => setCustomerSearchFilter(e.target.value)}
                            className='input input__form'
                        />
                    </div>
                </Col>*/}
                <Col xs={12} md={12}>
                    <div>
                        Dealer Filter:
                        <Select 
                            options={allDealersLabel}
                            value={dealerLabel}
                            onChange={handleDealerChange}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12}>
                    <div>
                        Date Filter: 
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DemoContainer 
                                components={['DatePicker', 'DatePicker']}
                                sx={{
                                    "&.MuiStack-root": {
                                        paddingTop: "0px"
                                    },
                                    "& .MuiInputBase-input": {
                                      fontSize: "1.6rem"
                                    }
                                }}
                            >
                                <DatePicker  
                                    value={startDate}
                                    onChange={(e) => setStartDate(e)}
                                    slotProps={{
                                        popper: {
                                            sx: {
                                                ".MuiPaper-root": { 
                                                    borderRadius: "10px",
                                                    paddingTop: "0px"
                                                },
                                                ".MuiInputBase-formControl": {
                                                    borderRadius: "20px"
                                                },
                                                ".MuiDayCalendar-weekDayLabel": {
                                                    fontSize: "1.6rem"
                                                },
                                                ".MuiPickersDay-root": {
                                                    fontSize: "1.6rem"
                                                },
                                                ".MuiPickersCalendarHeader-label": {
                                                    fontSize: "1.6rem"
                                                },
                                                zIndex: 9999
                                            }
                                        }
                                    }}
                                />
                                <DatePicker
                                    value={endDate}
                                    onChange={(e) => setEndDate(e)}
                                    slotProps={{
                                        popper: {
                                            sx: {
                                                ".MuiPaper-root": { 
                                                    borderRadius: "10px",
                                                    paddingTop: "0px"
                                                },
                                                ".MuiInputBase-formControl": {
                                                    borderRadius: "20px"
                                                },
                                                ".MuiDayCalendar-weekDayLabel": {
                                                    fontSize: "1.6rem"
                                                },
                                                ".MuiPickersDay-root": {
                                                    fontSize: "1.6rem"
                                                },
                                                ".MuiPickersCalendarHeader-label": {
                                                    fontSize: "1.6rem"
                                                },
                                                zIndex: 9999
                                            }
                                        }
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </Col>
            </Row>
            <div className='modal-footer'>
                <button
                    onClick={() => handleFiltersModalClose()}
                    className='footer__cancel-button'
                >
                    <div className="vertical-align-div">
                        <span>Cancel</span>
                    </div>
                    
                </button>
                <button
                    onClick={() => onSubmit()}
                    className='footer__save-button'
                >
                    <div className='vertical-align-div'>
                        <span>Apply Filters</span>
                    </div>
                </button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        contractsFilters: state.contractsFilters
    };
};

export default connect(mapStateToProps)(ContractFiltersModal);